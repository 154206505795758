import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, TextField, Button, Divider, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import { TemplateTwoContext } from './TemplateTwoContext';
import { uploadImage } from '../../components/ImageUploader';
import Swal from 'sweetalert2';

export default function UpdateInstance() {
   const { updateTemp2Instance, loading, setLoading, instance, instances } = useContext(TemplateTwoContext);
   const { instanceId } = useParams();
   const [detail, setDetail] = useState(instance);
   const [subjectExists, setSubjectExists] = useState(false); // State for subject existence

   useEffect(() => {
      // Prefill the form with the initial data
      setDetail(instance);
   }, [instance]);

   useEffect(() => {
      // Check if the subject already exists in the instances, excluding itself
      const exists = instances.some(inst => inst.subject.toLowerCase() === detail.subject.toLowerCase() && inst.id !== instance.id);
      setSubjectExists(exists);
   }, [detail.subject, instances, instance]);

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setDetail({ ...detail, [name]: value });
   };

   const handleImageChange = (e) => {
      const { name, files } = e.target;
      if (files.length > 0) {
         setDetail({ ...detail, [name]: files[0] });
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission behavior

      setLoading(true);
  
      // Check if detail is populated
      if (!detail) {
         console.error("Detail object is not populated");
         return; // Exit the function if detail is not available
      }
  
      // Prepare the JSON object
      const jsonData = {
         detail: detail
      };
  
      // Prepare to handle images
      const imagesToUpload = [
         { key: 'head_image', value: detail.head_image },
         { key: 'sub1_image', value: detail.sub1_image },
         { key: 'sub2_image', value: detail.sub2_image },
         { key: 'sub3_image', value: detail.sub3_image }
      ];
  
      for (const { key, value } of imagesToUpload) {
         if (value instanceof File) {
            try {
               const result = await uploadImage(value);
               
               // Add image URL and ID to jsonData after successful upload
               jsonData.detail[`${key}_id`] = result.id; // Store image ID
               jsonData.detail[key] = result.url; // Store image URL
            } catch (error) {
               console.error(`Error uploading ${key}:`, error);
               Swal.fire({
                  position: "top-end",
                  icon: "warning",
                  text: error || `An error occurred during ${key} upload.`,
                  showConfirmButton: false,
                  timer: 1500,
                  toast: true,
                  color: 'wheat',
                  background: 'red'
               });
               return; // Terminate the function if there is an error
            }
         } else if (value) {
            // Use the existing image data if it's not a new file
            jsonData.detail[key] = value; 
            jsonData.detail[`${key}_id`] = detail[`${key}_id`]; // Assuming you have the IDs in the detail object
         }
      }
  
      try {
         // Call the function to update the instance with JSON data
         await updateTemp2Instance(instanceId, jsonData?.detail);
         // Handle success (e.g., show a success notification)
      } catch (error) {
         console.error("Failed to update the template instance:", error);
         // Handle error (e.g., show an error notification to the user)
      } 
   };

   if (loading) {
      return (
         <LoadingComponent loadingText={'Updating ....'} />
      );
   }

   return (
      <Container maxWidth="md" sx={{ my: 5 }}>
         <Typography variant="h4" component="h2" align="center" gutterBottom>
            Update Instance
         </Typography>
         <Box component="form" onSubmit={handleSubmit} sx={{ bgcolor: 'lightgrey', p: 3, borderRadius: 1 }}>
            <Box mb={3}>
               <Typography variant="h6" component="h3" gutterBottom>
                  Header Section
               </Typography>
               <TextField 
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={detail?.subject}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  error={subjectExists}
                  helperText={subjectExists ? 'Subject already exists!' : ''}
               />
               <TextField 
                  fullWidth
                  label="Head Title"
                  name="head_title"
                  value={detail?.head_title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
               />
               <TextField
                  fullWidth
                  label="Head Text"
                  name="head_text"
                  value={detail?.head_text}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  multiline
                  rows={2}
               />
               <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2 }}
               >
                  Upload Header Image
                  <input
                     type="file"
                     name="head_image"
                     accept=".png, .jpg, .jpeg"
                     style={{ display: 'none' }}
                     onChange={handleImageChange}
                  />
               </Button>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box mb={3}>
               <Typography variant="h6" component="h3" gutterBottom>
                  Main Section
               </Typography>
               <TextField
                  fullWidth
                  label="Main Title"
                  name="main_title"
                  value={detail?.main_title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
               />
               <TextField
                  fullWidth
                  label="Main Text"
                  name="main_text"
                  value={detail?.main_text}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  multiline
                  rows={3}
               />
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box mb={3}>
               <Typography variant="h6" component="h3" gutterBottom>
                  Sub1 Section
               </Typography>
               <TextField
                  fullWidth
                  label="Sub 1 Title"
                  name="sub1_title"
                  value={detail?.sub1_title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
               />
               <TextField
                  fullWidth
                  label="Sub 1 Text"
                  name="sub1_text"
                  value={detail?.sub1_text}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  multiline
                  rows={4}
               />
               <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2 }}
               >
                  Upload Sub 1 Image
                  <input
                     type="file"
                     name="sub1_image"
                     accept=".png, .jpg, .jpeg"
                     style={{ display: 'none' }}
                     hidden
                     onChange={handleImageChange}
                  />
               </Button>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box mb={3}>
               <Typography variant="h6" component="h3" gutterBottom>
                  Sub2 Section
               </Typography>
               <TextField
                  fullWidth
                  label="Sub 2 Title"
                  name="sub2_title"
                  value={detail?.sub2_title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
               />
               <TextField
                  fullWidth
                  label="Sub 2 Text"
                  name="sub2_text"
                  value={detail?.sub2_text}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  multiline
                  rows={4}
               />
               <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2 }}
               >
                  Upload Sub 2 Image
                  <input
                     type="file"
                     name="sub2_image"
                     accept=".png, .jpg, .jpeg"
                     style={{ display: 'none' }}
                     hidden
                     onChange={handleImageChange}
                  />
               </Button>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box mb={3}>
               <Typography variant="h6" component="h3" gutterBottom>
                  Sub3 Section
               </Typography>
               <TextField
                  fullWidth
                  label="Sub 3 Title"
                  name="sub3_title"
                  value={detail?.sub3_title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
               />
               <TextField
                  fullWidth
                  label="Sub 3 Text"
                  name="sub3_text"
                  value={detail?.sub3_text}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                  multiline
                  rows={4}
               />
               <Button
                  variant="contained"
                  component="label"
                  sx={{ mt: 2 }}
               >
                  Upload Sub 3 Image
                  <input
                     type="file"
                     name="sub3_image"
                     accept=".png, .jpg, .jpeg"
                     style={{ display: 'none' }}
                     hidden
                     onChange={handleImageChange}
                  />
               </Button>
            </Box>
            <Divider sx={{ mb: 3 }} />
            <TextField
               fullWidth
               label="Learn More Link"
               name="view_more_link"
               value={detail?.view_more_link}
               onChange={handleInputChange}
               margin="normal"
               required
               type="url"
            />
            <Button
               type="submit"
               variant="contained"
               color="primary"
               fullWidth
               sx={{ mt: 2 }}
               disabled={subjectExists} // Disable if subject exists
            >
               Submit
            </Button>
         </Box>
      </Container>
   );
}
