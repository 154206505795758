import React, { useState } from 'react';
import './ImageViewer.css'; // Ensure this CSS file is created for styling
import { MdClose } from 'react-icons/md';
import { FaMagnifyingGlassMinus, FaMagnifyingGlassPlus  } from 'react-icons/fa6';


const ImageViewer = ({ images,isOpen,setIsOpen, currentImageIndex, setCurrentImageIndex ,scale, setScale, openModal}) => {
  
  const [fade, setFade] = useState(false);

  

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImageIndex(0);
    setScale(1); // Reset scale when closing modal
  };

  const nextImage = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFade(false);
      setScale(1); 
    }, 300); // Match the duration of the fade-out animation
  };

  const prevImage = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex - 1 + images.length) % images.length
      );
      setFade(false);
      setScale(1); 
    }, 300); // Match the duration of the fade-out animation
  };

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max scale of 3
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 1)); // Min scale of 1
  };

  return (
    <div className="image-viewer">
      {/* <div className="image-thumbnails">
        {images.length > 0 && images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => openModal(index)}
            className="thumbnail"
          />
        ))}
      </div> */}

      {isOpen && (
        <div className="modal" >
          <button className="close-button" onClick={closeModal}>
            <MdClose />
          </button>
          <div className={`image-container ${fade ? 'fade' : ''}`}>
            <img
              src={images[currentImageIndex]}
              alt="Large view"
              className="modal-image"
              style={{ transform: `scale(${scale})` }} // Apply scale to the image
            />
          </div>
          { images.length > 1 && (
            <div className="indicators">
            {images.map((_, index) => (
              <span
                key={index}
                className={`indicator ${currentImageIndex === index ? 'active' : ''}`}
              />
            ))}
          </div>
          )}
          {images.length > 1 && (
            <>
              <button className="nav-button left" onClick={prevImage}>
                &#10094; {/* Left arrow */}
              </button>
              <button className="nav-button right" onClick={nextImage}>
                &#10095; {/* Right arrow */}
              </button>
            </>
          )}
          <button className="zoom-button zoom-in btn border border-success " onClick={zoomIn}>
            <FaMagnifyingGlassPlus color="wheat"/> {/* Zoom In */}
          </button>
          <button className="zoom-button zoom-out btn border border-success" onClick={zoomOut}>
           <FaMagnifyingGlassMinus color="wheat"/> {/* Zoom Out */}
          </button>
         
        </div>
      )}
    </div>
  );
};

export default ImageViewer;
