import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { TemplateTwoContext } from './TemplateTwoContext'

export default function DeleteInstance() {
   const {instanceId} = useParams()
   const {deleteTemp2Instance} = useContext(TemplateTwoContext)


   function handleDelete(){
      Swal.fire({
         title: "Are you sure?",
         text: "Note: This will delete all the logs related to this Template.You won't be able to revert this!Would you like to continue?",
         icon: "warning",
         color:'wheat',
         toast:true,
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes, delete!",
         background:'rgb(255, 0, 0,0.8)' // change the colorcode 
       }).then((result) => {
         if (result.isConfirmed) {
          deleteTemp2Instance(instanceId)
         }
       });
   }
  return (
   <button onClick={handleDelete} className="btn btn-sm btn-danger mr-2">Delete</button>
  )
}
