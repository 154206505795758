import React,{useContext} from 'react'
import {LogContext} from '../context/LogContext'
import LoadingComponent from '../components/LoadingComponent'
import LogSummaryDetails from './LogSummaryDetails'
import RefreshLogs from './RefreshLogs'

export default function Logs() {
  const {logsSummary,loading, formatToCustomDate} = useContext(LogContext)
  if(loading){
return(<LoadingComponent loadingText={'Loading logs'}/>)
  }
  
 
  return (
    <div className='container-fluid p-3 card'>
      <div className='text-end'></div>
      <h2 className="text-center card-header bg-success" style={{fontFamily:'junge',color:'wheat'}}>Email Sending Logs Dashboard 
      <span className='px-2 ms-2'><RefreshLogs/></span>
      </h2>
      
      <div className="table-responsive border border-success bg-secondary rounded">
        <table className="table table-striped table-dark table-bordered rounded table-hover" style={{ borderCollapse: 'separate', borderSpacing: '1px 1px' }}>
          <thead className="thead-dark">
            <tr className='text-center'>
              <th className='bg-secondary' scope="col">Template</th>
              <th  className='bg-secondary' scope="col">Subject</th>
              <th  className='bg-secondary' scope="col">Type</th>
              <th  className='bg-secondary' scope="col">Recipients</th>
              <th  className='bg-secondary' scope="col">Opens</th>
              <th  className='bg-secondary' scope="col">Time</th>
              <th  className='bg-secondary' scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {logsSummary && logsSummary.length > 0 ? ( logsSummary.map((log, index) => (
              <tr style={{cursor:'pointer'}} className='rounded text-center pe-3' key={index} >
                <td>{log.email_template}</td>
                <td>{log.subject}</td>
                <td>{log.email_type}</td>
                <td>{log.recipients}</td>
                <td>{log.opens}</td>
                <td>{formatToCustomDate(log.time_sent)}</td>
                <td><LogSummaryDetails summaryLog={log}/></td>
              </tr>
            ))):(

              <tr>
              <td colSpan="7" className="text-center">
                <div className="alert alert-warning text-dark" role="alert" style={{width:"100%", height:"auto"}}>
                  No emails sent
                </div>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
