import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from '../../utils/ImageViewer';
import { TemplateOneContext } from './TemplateOneContext';
import logo from '../../images/logo.png';

const Preview = () => {
  const { instance } = useContext(TemplateOneContext);


const FACEBOOK_LINK ='https://www.facebook.com/beshafricanadventures'
const INSTAGRAM_LINK='https://www.instagram.com/besh_african_adventures'
const YOUTUBE_LINK='https://www.youtube.com/@beshafricanadventures2324'
const LINKEDIN_LINK='https://www.linkedin.com/in/besh-african-adventures-174ba31b6'
const X_LINK= 'https://x.com/BeshAdventures'


// # https://package2-besh-tours-managing-app.vercel.app/inquiries/add
const WEBSITE_LINK='https://beshafricanadventures.co.ke '
// const BOOK_NOW ='https://beshafricanadventures.co.ke ' 



  const images = [instance?.image];
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scale, setScale] = useState(1);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setScale(1);
    setIsOpen(true);
  };

  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            background-color: rgb(244, 244, 244, 0);
            margin: 0;
            padding: 0;
            line-height: 1.6;
            color: #444;
          }
          .container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            padding:0;
            background-color: rgb(152, 255, 152, 0.4);
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          .header {
            background-color: #98FF98;
            text-align: center;
            padding: 30px 0;
            border-bottom: 3px solid #808000;
          }
          .header img {
            height: 100px;
            display: block;
            margin: 0 auto;
          }
          .header h1 {
            font-size: clamp(1.5em, 2vw, 2.5em);
            color: #333;
            margin: 20px 0 10px 0;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
          .content {
            padding: 20px;
            text-align: center;
          }
          .main-image {
            width: 100%;
            max-width: 100%;
            border-radius: 1px;
            margin-top: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
          .button {
            display: inline-block;
            padding: 10px 40px;
            margin: 10px 30px;
            background-color: #32CD32;
            color: #FFD700;
            text-decoration: none;
            border: 1px solid #808000;
            border-radius: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .footer_preview {
            background-color: #333;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-top: 1px solid #1e1e1e;
            font-size: 0.9em;
          }
          .social-icons {
            margin: 20px 0;
            display: inline-block;
          }
          .social-icons a {
            margin: 0 5px;
            display: inline-block;
          }
          .social-icons img {
            width: 30px;
            height: 30px;
            border: none;
          }
        `}
      </style>

      <div className="container container my-4">
        <div className="header">
          <img src={logo} alt="Logo" />
          <h1>{instance?.title}</h1>
        </div>
        <div className="content">
          <ImageViewer 
            images={images}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentImageIndex={currentImageIndex}
            setCurrentImageIndex={setCurrentImageIndex}
            scale={scale}
            setScale={setScale}
          />
          <p style={{ textAlign: 'left', margin: 0 }}>
            Hello,
          </p>
          <img src={instance?.image} alt="Tour" 
            onClick={() => openModal(0)} className="main-image" />
          <p>
            {instance?.text}{' '}
            <a href={instance?.view_more_link} className="view-more-link">
              View More
            </a>.
          </p>
          <div>
            <a href={WEBSITE_LINK} className="button">
              Go to Website
            </a>
          </div>
        </div>
        <div className="footer_preview">
          <div className="social-icons">
            <a href={FACEBOOK_LINK}>
              <img src="https://img.icons8.com/ios-filled/50/1877F2/facebook-new.png" alt="Facebook" />
            </a>
            <a href={X_LINK}>
              <img src="https://ik.imagekit.io/4rfgtafuc0/social%20media/x(white).png?updatedAt=1725557546866" alt="X" />
            </a>
            <a href={INSTAGRAM_LINK}>
              <img src="https://www.pngmart.com/files/21/Instagram-PNG-Isolated-File.png" alt="Instagram" />
            </a>
            <a href={LINKEDIN_LINK}>
              <img src="https://img.icons8.com/ios-filled/50/0072b1/linkedin.png" alt="LinkedIn" />
            </a>
            <a href={YOUTUBE_LINK}>
              <img src="https://img.icons8.com/ios-filled/50/ff0000/youtube-play.png" alt="YouTube" />
            </a>
          </div>
          <p>&copy; 2024 Besh African Adventures. All rights reserved.</p>
          <p>
            <a href="#/" style={{ color: '#28a745', textDecoration: 'none' }}>Unsubscribe</a> | 
            <Link to="/pages/privacy-policy" style={{ color: '#28a745', textDecoration: 'none' }}> Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Preview;
