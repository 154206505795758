import React, { useState, useEffect, useContext } from 'react';
import { Container, TextField, Typography, Button, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import LoadingComponent from '../../components/LoadingComponent';
import { TemplateTwoContext } from './TemplateTwoContext';
import { uploadImage } from '../../components/ImageUploader';
import Swal from 'sweetalert2';

export default function AddInstance() {
  const navigate = useNavigate();
  const { addTemp2Instance, loading, setLoading, instances } = useContext(TemplateTwoContext);

  const initialDetails = {
    subject: '',
    head_title: '',
    head_text: '',
    headImage: null,
    main_title: '',
    main_text: '',
    sub1_title: '',
    sub1_text: '',
    sub1Image: null,
    sub2_title: '',
    sub2_text: '',
    sub2Image: null,
    sub3_title: '',
    sub3_text: '',
    sub3Image: null,
    view_more_link: ''
  };

  const getInitialDetails = () => {
    const savedDetails = localStorage.getItem('details');
    return savedDetails ? JSON.parse(savedDetails) : initialDetails;
  };

  const [details, setDetails] = useState(getInitialDetails);
  const [subjectExists, setSubjectExists] = useState(false); // State for subject existence

  useEffect(() => {
    localStorage.setItem('details', JSON.stringify(details));
  }, [details]);

  useEffect(() => {
    // Check if the subject already exists in the instances
    const exists = instances.some(instance => instance.subject.toLowerCase() === details.subject.toLowerCase());
    setSubjectExists(exists);
  }, [details.subject, instances]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setDetails({ ...details, [name]: files[0] });
    }
  };

  const uploadImageToJson = async (imageField) => {
    try {
      const result = await uploadImage(details[imageField]);
      return { url: result.url, id: result.id };
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        position: "top-end",
        icon: "warning",
        text: error || "An error occurred during image upload.",
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        color: 'wheat',
        background: 'red'
      });
      throw error; // Rethrow error to stop the submission process
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedDetails = { ...details };

    try {
      setLoading(true);

      // Upload images and store their URLs and IDs in the updatedDetails object
      if (details.headImage instanceof File) {
        const headImageResult = await uploadImageToJson('headImage');
        updatedDetails.headImage = headImageResult.url;
        updatedDetails.headImageId = headImageResult.id;
      }

      if (details.sub1Image instanceof File) {
        const sub1ImageResult = await uploadImageToJson('sub1Image');
        updatedDetails.sub1Image = sub1ImageResult.url;
        updatedDetails.sub1ImageId = sub1ImageResult.id;
      }

      if (details.sub2Image instanceof File) {
        const sub2ImageResult = await uploadImageToJson('sub2Image');
        updatedDetails.sub2Image = sub2ImageResult.url;
        updatedDetails.sub2ImageId = sub2ImageResult.id;
      }

      if (details.sub3Image instanceof File) {
        const sub3ImageResult = await uploadImageToJson('sub3Image');
        updatedDetails.sub3Image = sub3ImageResult.url;
        updatedDetails.sub3ImageId = sub3ImageResult.id;
      }

      const isValidDetail = (detail) => detail !== null && detail !== "" && detail !== undefined;
      const invalidDetails = Object.entries(updatedDetails).filter(([key, value]) => !isValidDetail(value));

      if (invalidDetails.length === 0) {
        setDetails(updatedDetails);
        await addTemp2Instance(updatedDetails);
      } else {
        const invalidKeys = invalidDetails.map(([key]) => key).join(", ");
        Swal.fire({
          title: "Invalid Details",
          text: `The following details are invalid: ${invalidKeys}`,
          icon: "error",
          button: "Okay",
        });
      }
    } catch (error) {
      console.error("Submission failed:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingComponent loadingText="Adding instance ...." />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Add Template 2 Instance
      </Typography>
      <button onClick={() => navigate('/templates/2/templates')} className="btn btn-outline-danger btn-sm mr-2 m-2">
        <BsArrowLeft /> Instances
      </button>
      <Box component="form" onSubmit={handleSubmit} sx={{ bgcolor: 'white', p: 3, borderRadius: 1 }}>
        {/* Header Section */}
        <Box mb={3}>
          <Typography variant="h6" component="h3" gutterBottom>
            Header Section
          </Typography>
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={details.subject}
            onChange={handleInputChange}
            margin="normal"
            required
            error={subjectExists}
            helperText={subjectExists ? 'Subject already exists!' : ''}
          />
          <TextField fullWidth label="Head Title" name="head_title" value={details.head_title} onChange={handleInputChange} margin="normal" required />
          <TextField fullWidth label="Head Text" name="head_text" value={details.head_text} onChange={handleInputChange} margin="normal" required multiline rows={2} />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Header Image
            <input type="file" name="headImage" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} hidden onChange={handleImageChange} />
          </Button>
        </Box>
        <Divider sx={{ mb: 3 }} />

        {/* Main Section */}
        <Box mb={3}>
          <Typography variant="h6" component="h3" gutterBottom>
            Main Section
          </Typography>
          <TextField fullWidth label="Main Title" name="main_title" value={details.main_title} onChange={handleInputChange} margin="normal" required />
          <TextField fullWidth label="Main Text" name="main_text" value={details.main_text} onChange={handleInputChange} margin="normal" required multiline rows={3} />
        </Box>
        <Divider sx={{ mb: 3 }} />

        {/* Sub1 Section */}
        <Box mb={3}>
          <Typography variant="h6" component="h3" gutterBottom>
            Sub1 Section
          </Typography>
          <TextField fullWidth label="Sub 1 Title" name="sub1_title" value={details.sub1_title} onChange={handleInputChange} margin="normal" required />
          <TextField fullWidth label="Sub 1 Text" name="sub1_text" value={details.sub1_text} onChange={handleInputChange} margin="normal" required multiline rows={4} />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Sub 1 Image
            <input type="file" name="sub1Image" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} hidden onChange={handleImageChange} />
          </Button>
        </Box>
        <Divider sx={{ mb: 3 }} />

        {/* Sub2 Section */}
        <Box mb={3}>
          <Typography variant="h6" component="h3" gutterBottom>
            Sub2 Section
          </Typography>
          <TextField fullWidth label="Sub 2 Title" name="sub2_title" value={details.sub2_title} onChange={handleInputChange} margin="normal" required />
          <TextField fullWidth label="Sub 2 Text" name="sub2_text" value={details.sub2_text} onChange={handleInputChange} margin="normal" required multiline rows={4} />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Sub 2 Image
            <input type="file" name="sub2Image" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} hidden onChange={handleImageChange} />
          </Button>
        </Box>
        <Divider sx={{ mb: 3 }} />

        {/* Sub3 Section */}
        <Box mb={3}>
          <Typography variant="h6" component="h3" gutterBottom>
            Sub3 Section
          </Typography>
          <TextField fullWidth label="Sub 3 Title" name="sub3_title" value={details.sub3_title} onChange={handleInputChange} margin="normal" required />
          <TextField fullWidth label="Sub 3 Text" name="sub3_text" value={details.sub3_text} onChange={handleInputChange} margin="normal" required multiline rows={4} />
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Upload Sub 3 Image
            <input type="file" name="sub3Image" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} hidden onChange={handleImageChange} />
          </Button>
        </Box>
        <Divider sx={{ mb: 3 }} />

        <TextField fullWidth label="Learn More Link" name="view_more_link" value={details.view_more_link} onChange={handleInputChange} margin="normal" required type="url" />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={subjectExists}>
          Submit
        </Button>
      </Box>
    </Container>
  );
}
