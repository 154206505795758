import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { Container, Typography, TextField, Button, Grid, Box, Paper } from '@mui/material';
import { TemplateOneContext } from './TemplateOneContext';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import { uploadImage } from '../../components/ImageUploader';
import Swal from 'sweetalert2';

export default function AddInstance() {
  const { addTemp1Instance, loading, setLoading, instances } = useContext(TemplateOneContext);
  const navigate = useNavigate();

  const savedFormData = JSON.parse(localStorage.getItem('formData')) || {
    subject: '',
    title: '',
    text: '',
    viewmoreUrl: '',
    file: null
  };

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: savedFormData
  });

  const [formData, setFormData] = useState({ imageFile: null });
  const [subjectExists, setSubjectExists] = useState(false); // State for subject existence

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({ ...prevState, imageFile: file }));
      localStorage.setItem('imageFile', file); // Store in localStorage
    }
  };

  const onSubmit = async (data) => {
    const jsonData = {
      subject: data.subject,
      title: data.title,
      text: data.text,
      view_more_link: data.viewmoreUrl,
    };

    try {
      if (formData.imageFile) {
        const result = await uploadImage(formData.imageFile);
        jsonData.image = result.url; 
        jsonData.image_id = result.id; 
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      Swal.fire({
        position: "top-end",
        icon: "warning",
        text: error || "An error occurred during image upload.",
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        color: 'wheat',
        background: 'red'
      });
      return; 
    }

    try {
      if (jsonData && jsonData?.image) {
        localStorage.setItem('formData', JSON.stringify(jsonData));
        await addTemp1Instance(jsonData); 
        setLoading(true);
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          text: "Please upload an image to continue!",
          showConfirmButton: false,
          timer: 5000,
          toast: true,
          color: 'wheat',
          background: 'red'
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const watchedFields = watch();

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(watchedFields));
  }, [watchedFields]);

  useEffect(() => {
    const savedImageFile = localStorage.getItem('imageFile');
    if (savedImageFile) {
      setFormData((prevState) => ({ ...prevState, imageFile: savedImageFile }));
    }
  }, []);

  // Real-time validation for subject
  useEffect(() => {
    if (watchedFields.subject) {
      const exists = instances.some(instance => instance.subject.toLowerCase() === watchedFields.subject.toLowerCase());
      setSubjectExists(exists);
    } else {
      setSubjectExists(false);
    }
  }, [watchedFields.subject, instances]);

  if (loading) {
    const loadingText = 'Adding an instance ....';
    return <LoadingComponent loadingText={loadingText} />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <button onClick={() => navigate('/templates/1/templates')} className="btn btn-outline-danger btn-sm mr-2 m-2">
        <BsArrowLeft /> Instances
      </button>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Add New Instance
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('subject', { required: true, minLength: 3 })}
                fullWidth
                label="Subject"
                variant="outlined"
                error={!!errors.subject || subjectExists}
                helperText={errors.subject ? 'Subject is required and must be at least 3 characters' : subjectExists ? 'Subject already exists!' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('title', { required: true, minLength: 5 })}
                fullWidth
                label="Title"
                variant="outlined"
                error={!!errors.title}
                helperText={errors.title ? 'Title is required and must be at least 5 characters' : ''}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('text', { required: true, minLength: 10 })}
                fullWidth
                multiline
                rows={3}
                label="Text"
                variant="outlined"
                error={!!errors.text}
                helperText={errors.text ? 'Text is required and must be at least 10 characters' : ''}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="imageFile">Image File</label>
              <input
                {...register('imageFile', { required: true })}
                type="file"
                id="imageFile"
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              <label htmlFor="imageFile">
                <Button variant="outlined" component="span">
                  Upload Image
                </Button>
              </label>
              {errors.imageFile && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  Image is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('viewmoreUrl', { required: true })}
                fullWidth
                label="Viewmore URL"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button type="submit" variant="contained" color="primary" disabled={subjectExists}>
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
