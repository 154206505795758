import React from 'react';
import * as XLSX from 'xlsx';
import { FaDownload } from 'react-icons/fa';

export default function DownloadEmails({ emails }) {
  const handleDownload = () => {
    // Extract only the email addresses from the array
    const emailList = emails.map((email) => ({ email: email.email }));

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(emailList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Emails');

    // Generate a timestamp
    const now = new Date();
    const timestamp = now.toISOString().slice(0, 19).replace(/:/g, '-');
    const fileName = `client_emails_${timestamp}.xlsx`;

    // Generate an XLSX file and trigger download with the timestamp in the name
    XLSX.writeFile(workbook, fileName);
  };

  return (
      <button
        onClick={handleDownload}
        className="btn btn-primary btn-sm"
       title='Download emails file'
      >
        <FaDownload/>
        <small>Download emails</small>
      </button>
  );
}
