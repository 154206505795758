import React, { createContext,useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axiosInstance from '../utils/RequestsQueue'; // Import the RequestQueue instance
import {AuthContext} from './AuthContext'

export const EmailContext = createContext();

export default function EmailProvider({ children }) {
  const  authToken  = localStorage.getItem('beshNewsletterAuthToken')
  const [emails, setEmails] = useState([]);
  const [emailChange, setEmailChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loggedIn } = useContext(AuthContext);

  // ============================= FETCH EMAILS ==============================
  const fetchEmails = async () => {
    setLoading(true); // Start loading
    try {
      if (authToken) {
        const response = await axiosInstance.requestWithQueue({
          method: 'GET',
          url: 'https://besh.newsletter.safarinetics.com/emails/all',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = response.data;
        if (!data.error && data.length >= emails.length) {
          setEmails(data);
        }
      }
    } catch (error) {
      console.error('Error fetching emails:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    // Fetch emails initially
    fetchEmails();

    // Set up interval to fetch emails every 3 minutes
    const intervalId = setInterval(fetchEmails, 3 * 60 * 1000); // 3 minutes in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [emailChange, authToken,loggedIn]);


  // ============================= ADD EMAIL ==============================
  const addEmail = async (email) => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'POST',
        url: 'https://besh.newsletter.safarinetics.com/emails/add/api',
        data: { email },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      // console.log(response)

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
        setEmailChange(!emailChange);
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 4000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  // ============================= ADD BULK EMAILS ==============================
  const addBulkEmail = async (emails) => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'POST',
        url: 'https://besh.newsletter.safarinetics.com/emails/add/bulk',
        data: { emails },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          text: `${response.data.success}\nSuccess: ${response.data.success_count}\nFailed: ${response.data.error_count}`,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
        setEmailChange(!emailChange);
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while processing your request.',
        position: 'top-end',
        timer: 24000,
        toast: true,
        showConfirmButton: false,
        background: 'red',
        color: 'wheat',
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  // ============================= UNSUBSCRIBE EMAIL ==============================
  const unsubscribeEmail = async (email) => {
    setLoading(true); // Start loading
    try {
      const response = await axiosInstance.requestWithQueue({
        method: 'GET',
        url: `https://besh.newsletter.safarinetics.com/emails/unsubscribe/${email}/app`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          text: response.data.success,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: '#228B22',
          color: 'wheat',
        });
        setEmailChange(!emailChange);
      } else {
        Swal.fire({
          icon: 'warning',
          text: response.data.error || response.data.msg,
          position: 'top-end',
          timer: 24000,
          toast: true,
          showConfirmButton: false,
          background: 'red',
          color: 'wheat',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const contextData = {
    emails,
    emailChange,
    setEmailChange,
    addEmail,
    unsubscribeEmail,
    loading,
    setLoading,
    addBulkEmail,
    fetchEmails,
  };

  return <EmailContext.Provider value={contextData}>{children}</EmailContext.Provider>;
}
