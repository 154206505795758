import React, { useContext, useState } from 'react';
import backgroundImage from '../images/lion.png'; // Import your background image
import { AuthContext } from '../context/AuthContext';
import { motion } from 'framer-motion';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useParams } from 'react-router-dom';

const ResetChangePassword = () => {
  const { resetChangePassword, loading, setLoading } = useContext(AuthContext);
  const { email } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [strength, setStrength] = useState(0); // Password strength state
  const [confirmError, setConfirmError] = useState(''); // Confirm password error state

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const calculateStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score += 20;
    if (/[A-Z]/.test(password)) score += 20;
    if (/[a-z]/.test(password)) score += 20;
    if (/\d/.test(password)) score += 20;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 20;
    setStrength(score);
  };

  const handleConfirmPasswordChange = (password) => {
    setConfirmPassword(password);
    if (password !== newPassword) {
      setConfirmError('*Passwords do not match.');
    } else {
      setConfirmError('');
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    setError(''); // Clear previous errors

    // Validate passwords
    if (strength < 80) {
      setError('Password must be strong (at least 80% strength).');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    resetChangePassword({ email, newPassword })
    setLoading(true);
    // Call resetPassword function here with the new password
    // resetPassword(email, newPassword);
  }

  const strengthBarColor = () => {
    if (strength < 50) return 'red';
    if (strength < 80) return 'yellow';
    return 'green';
  };

  const isSubmitDisabled = () => {
    return strength < 80 || newPassword !== confirmPassword || loading;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        style={{
          textAlign: 'center',
          backgroundColor: 'rgba(5,15,27,0.8)', // 60% transparent white background
          padding: '40px',
          borderRadius: '10px',
          border: '1px solid #ccc',
          maxWidth: '400px',
          width: '80%',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <h2 style={{ fontFamily: 'Arial, sans-serif', color: 'lightgreen', marginBottom: '20px' }}>Change Password</h2>
        {error && <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>}
        <form onSubmit={handleSubmit}>
          {/* <div style={{ marginBottom: '20px' }}>
            <input
              id='email'
              name='email'
              value={email}
              readOnly
              type="text"
              placeholder=""
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
              }}
            />
          </div> */}

          {/* Strength Bar and Percentage Above New Password Input */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: '5px', width: '100%', backgroundColor: '#e0e0e0', marginBottom: '10px' }}>
              <div
                style={{
                  height: '100%',
                  width: `${strength}%`,
                  backgroundColor: strengthBarColor(),
                  transition: 'width 0.5s ease-in-out',
                }}
              />
            </div>
            <p style={{ color: strengthBarColor() }}><small>({strength}%)</small></p>
          </div>

          {/* New Password Input */}
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <input
              required
              id='password'
              name='password'
              onChange={(e) => {
                setNewPassword(e.target.value);
                calculateStrength(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc'
              }}
            />
            <div
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
              }}
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </div>

          {confirmError && <div style={{ color: 'red', marginTop: '5px', fontSize: '15px' }}>{confirmError}</div>}

          {/* Confirm Password Input */}
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <input
              required
              id='confirmPassword'
              name='confirmPassword'
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              style={{
                borderRadius: '5px',
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc'
              }}
            />
            <div
              onClick={toggleConfirmPasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
              }}
            >
              {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            style={{
              width: '100%',
              borderRadius: '5px',
              padding: '10px',
              fontSize: '1rem',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
            }}
            disabled={isSubmitDisabled()}
          >
            {loading ? <Spinner /> : 'Submit'}
          </button>
        </form>
      </motion.div>
    </div>
  );
};

// Assuming Spinner is a component that renders a loading spinner
const Spinner = () => (
  <div className="spinner-border text-warning" role="status">
    <span className="sr-only"></span>
  </div>
);

export default ResetChangePassword;
