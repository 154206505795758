import ImageKit from 'imagekit-javascript';
import axiosInstance from '../utils/RequestsQueue'; // Adjust the import path

// SDK initialization
const imagekit = new ImageKit({
    publicKey: "public_DDurnqzKCqmG/dhBTALo8mX0n7o=",
    urlEndpoint:'https://ik.imagekit.io/eyc4vj4hp',
    authenticationEndpoint: "https://besh.newsletter.safarinetics.com/images/auth" // Update this to your Flask server's auth endpoint
});

// Fetch authentication parameters from the server using axiosInstance
async function getAuthParameters() {
    try {
        const response = await axiosInstance.requestWithQueue({
            method: 'GET',
            url: 'https://besh.newsletter.safarinetics.com/images/auth', // Update this to your Flask server's auth endpoint
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching authentication parameters:", error);
        throw error;
    }
}

// Upload function with resizing for website banners
async function upload(file) {
    const authParameters = await getAuthParameters();
    const timestamp = Math.floor(Date.now() / 1000); // Generate a unique timestamp in seconds
    const uniqueFileName = `${file.name.split('.')[0]}_${timestamp}.${file.name.split('.').pop()}`; // Add timestamp to filename

    return new Promise((resolve, reject) => {
        imagekit.upload({
            file: file,
            fileName: uniqueFileName,
            ...authParameters,
        }, function (err, result) {
            if (err) {
                reject(err);
            } else {
                const imageURL = imagekit.url({
                    path: result.filePath,
                    transformation: [
                        {
                            "height": "400",
                            "width": "600"
                        }
                    ]
                });
                resolve({ url: imageURL, id: result.fileId });
            }
        });
    });
}

export async function uploadImage(file) {
    try {
        const result = await upload(file);
        return { url: result.url, id: result.id };
    } catch (error) {
        console.error("Error uploading image:", error);
        return { error: "Error uploading image", details: error.message };
    }
}
