// Import necessary dependencies
import React, { useContext } from 'react';
import { EmailContext } from '../context/EmailContext';
import AddEmail from './AddEmail';
import Swal from 'sweetalert2';
import AddBulkEmails from './AddBulkEmails';
import LoadingComponent from '../components/LoadingComponent'
import { FaCheckCircle,FaTimesCircle } from 'react-icons/fa';
import DownloadEmails from './DownloadEmails';
import RefreshEmails from './RefreshEmails';



// Functional component for Emails
function Emails() {
  const { emails, unsubscribeEmail, loading } = useContext(EmailContext);

  function handleUnsubscribeEmail(email){
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unsubscribe!",
      background:'rgb(255, 165, 0,0.8)'
    }).then((result) => {
      if (result.isConfirmed) {
        unsubscribeEmail(email)
      }
    });
  }
  if(loading){
    return(
      <LoadingComponent loadingText={'Loading ...'} />
    )
  }

 

  // Render the component
  return (
    <div className="container-fluid mt-4">
      <div className='d-flex justify-content-start align-items-center gap-1'>
        <AddEmail/>
        <AddBulkEmails/>
        <DownloadEmails emails={emails} />
        <RefreshEmails/>
      </div>
      <h2 style={{fontFamily:'junge',color:'wheat'}} className="bg-success text-center fw-bold ">Emails</h2>

      <div className="table-responsive border border-success rounded">
        <table className="table table-striped table-dark table-bordered rounded table-hover" style={{ borderCollapse: 'separate', borderSpacing: '1px 0' }}>
          <thead className="thead-light">
            <tr className='text-center'>
              <th className='bg-secondary' scope="col">#</th>
              <th  className='bg-secondary' scope="col">Email</th>
              <th className='bg-secondary' scope="col">Subscribed</th>
              <th className='bg-secondary' scope="col">Added</th>
              <th className='bg-secondary' scope="col">Updated</th>
            </tr>
          </thead>
          <tbody>
            {emails && emails.length > 0 ?( emails.map((email, index) => (
              <tr style={{cursor:'pointer'}} className='rounded text-center' key={index} >
                <th scope="row">{index + 1} .</th>
                <td>{email.email}</td>
                <td>
                  {email.subscribed ? (
                    <button title='subscribed' onClick={() => handleUnsubscribeEmail(email.email)} className="btn btn-success btn-sm"><FaCheckCircle/> </button>
                  ) : (
                    <button title='unsubscribed' className="btn btn-danger btn-sm"><FaTimesCircle /></button>
                  )}
                </td>
                <td>{email.added_at}</td>
                <td>{email.updated_at}</td>
              </tr>
            ))):(
              
              <tr>
              <td colSpan="5" className="text-center">
                <div className="alert alert-warning text-dark" role="alert" style={{width:"100%", height:"auto"}}>
                  You currently have no emails uploaded in the system
                </div>
              </td>
            </tr>

            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Emails;
